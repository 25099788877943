var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadscrumbs',{staticClass:"header-bread-scrum",attrs:{"items":_vm.breadcrumbs}}),_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"mb-3",attrs:{"justify-space-between":"","align-center":""}},[_c('v-layout',{staticClass:"col-10 pa-0"},[_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('start_date'))+" ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"270px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":_vm.$t('place_holders.date-from'),"append-icon":"mdi-calendar","dense":"","flat":"","solo":"","outlined":"","error-messages":_vm.errorDate,"clearable":""},on:{"click:clear":_vm.onClearDateFrom,"click:append":function($event){_vm.menuDateFrom = !_vm.menuDateFrom},"input":_vm.onChangeDateFrom},model:{value:(_vm.dateFromValue),callback:function ($$v) {_vm.dateFromValue=$$v},expression:"dateFromValue"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[_c('v-date-picker',{attrs:{"format":"DD/MM/YYYY","locale":_vm.localDate,"no-title":""},on:{"input":function($event){_vm.menuDateFrom = false},"change":_vm.pickerDateFromChange},model:{value:(_vm.dateFromPicker),callback:function ($$v) {_vm.dateFromPicker=$$v},expression:"dateFromPicker"}})],1)],1),_c('div',{staticClass:"ml-3"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('end_date'))+" ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"270px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":_vm.$t('place_holders.date-to'),"append-icon":"mdi-calendar","dense":"","error-messages":_vm.errorDate,"flat":"","solo":"","outlined":"","clearable":""},on:{"click:clear":_vm.onClearDateTo,"click:append":function($event){_vm.menuDateTo = !_vm.menuDateTo},"input":_vm.onChangeDateTo},model:{value:(_vm.dateToValue),callback:function ($$v) {_vm.dateToValue=$$v},expression:"dateToValue"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateTo),callback:function ($$v) {_vm.menuDateTo=$$v},expression:"menuDateTo"}},[_c('v-date-picker',{attrs:{"format":"DD/MM/YYYY","locale":_vm.localDate,"no-title":""},on:{"input":function($event){_vm.menuDateTo = false},"change":_vm.pickerDateToChange},model:{value:(_vm.dateToPicker),callback:function ($$v) {_vm.dateToPicker=$$v},expression:"dateToPicker"}})],1)],1),_c('div',{staticClass:"ml-4",staticStyle:{"width":"124px"}},[_c('span',[_vm._v(_vm._s(_vm.$t('total_amount')))]),_c('v-text-field',{attrs:{"disabled":"","outlined":"","filled":""},model:{value:(_vm.totalOrder),callback:function ($$v) {_vm.totalOrder=$$v},expression:"totalOrder"}})],1)]),_c('v-btn',{staticClass:"col-2",attrs:{"color":"primary"},on:{"click":_vm.onClickSearch}},[_vm._v(" "+_vm._s(_vm.$t('search'))+" ")])],1),_c('v-data-table',{staticClass:"border-primary",attrs:{"hide-default-footer":"","fixed-header":"","no-data-text":_vm.$t('not_found'),"headers":_vm.headers,"header-props":{ sortIcon: 'mdi-menu-swap' },"items-per-page":20,"items":_vm.items,"height":"70vh"},scopedSlots:_vm._u([{key:"item.custName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-7 text-truncate"},[_c('span',{staticClass:"text-name-hover cursor-pointer",on:{"click":function($event){return _vm.goToDetailCust(item.custId)}}},[_vm._v(" "+_vm._s(item.custName)+" ")])])]}},{key:"item.waitOrder",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-7 text-truncate"},[_vm._v(" "+_vm._s(_vm.formatDecimal(item.amount-(item.successDelivery + item.pickFail)))+" ")])]}},{key:"item.successDelivery",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-7 text-truncate"},[_vm._v(" "+_vm._s(_vm.formatDecimal(item.successDelivery))+" ")])]}},{key:"item.pickFail",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-7 text-truncate"},[_vm._v(" "+_vm._s(_vm.formatDecimal(item.pickFail))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-7 text-truncate"},[_vm._v(" "+_vm._s(_vm.formatDecimal(item.amount))+" ")])]}},{key:"item.saleName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-7 text-truncate"},[_c('span',{staticClass:"text-name-hover cursor-pointer",on:{"click":function($event){return _vm.goToDetailEmployee(item.saleId)}}},[_vm._v(" "+_vm._s(item.saleName)+" ")])])]}},{key:"footer",fn:function(){return [_c('custom-pagination',{attrs:{"page":_vm.page,"start-record":_vm.computedStartRecord,"end-record":_vm.computedEndRecord,"total-record":_vm.totalRecord,"total-page":_vm.totalPage,"on-change-page":_vm.onChangePage}})]},proxy:true}],null,true)})],1),_c('dialog-loading',{attrs:{"message":_vm.$t('processing')},model:{value:(_vm.showLoading),callback:function ($$v) {_vm.showLoading=$$v},expression:"showLoading"}}),_c('dialog-notification',{attrs:{"icon-src":_vm.getIcon,"message":_vm.message},model:{value:(_vm.showNoti),callback:function ($$v) {_vm.showNoti=$$v},expression:"showNoti"}}),_c('dialog-confirm-token-expried',{model:{value:(_vm.showConfirmTokenExpried),callback:function ($$v) {_vm.showConfirmTokenExpried=$$v},expression:"showConfirmTokenExpried"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }