<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout justify-space-between class="mb-3" align-center>
        <v-layout class="col-10 pa-0">
          <div>
            <div>
              {{ $t('start_date') }}
            </div>
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="270px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFromValue"
                  :placeholder="$t('place_holders.date-from')"
                  append-icon="mdi-calendar"
                  dense
                  v-bind="attrs"
                  flat
                  solo
                  outlined
                  :error-messages="errorDate"
                  class="rounded-lg"
                  clearable
                  v-on="on"
                  @click:clear="onClearDateFrom"
                  @click:append="menuDateFrom = !menuDateFrom"
                  @input="onChangeDateFrom"
                />
              </template>
              <v-date-picker
                v-model="dateFromPicker"
                format="DD/MM/YYYY"
                :locale="localDate"
                no-title
                @input="menuDateFrom = false"
                @change="pickerDateFromChange"
              />
            </v-menu>
          </div>
          <div class="ml-3">
            <div>
              {{ $t('end_date') }}
            </div>
            <v-menu
              v-model="menuDateTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="270px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateToValue"
                  :placeholder="$t('place_holders.date-to')"
                  append-icon="mdi-calendar"
                  dense
                  v-bind="attrs"
                  :error-messages="errorDate"
                  flat
                  solo
                  outlined
                  clearable
                  class="rounded-lg"
                  v-on="on"
                  @click:clear="onClearDateTo"
                  @click:append="menuDateTo = !menuDateTo"
                  @input="onChangeDateTo"
                />
              </template>
              <v-date-picker
                v-model="dateToPicker"
                format="DD/MM/YYYY"
                :locale="localDate"
                no-title
                @input="menuDateTo = false"
                @change="pickerDateToChange"
              />
            </v-menu>
          </div>
          <div style="width: 124px;" class="ml-4">
            <span>{{ $t('total_amount') }}</span>
            <v-text-field
              v-model="totalOrder"
              disabled
              outlined
              filled
            />
          </div>
          <!-- Error date -->
          <!-- <span v-if="errorDate" class="text-date-error">
            {{ $t(errorDate) }}
          </span> -->
        </v-layout>
        <v-btn color="primary" class="col-2" @click="onClickSearch">
          {{ $t('search') }}
        </v-btn>
      </v-layout>
      <v-data-table
        class="border-primary"
        hide-default-footer
        fixed-header
        :no-data-text="$t('not_found')"
        :headers="headers"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items-per-page="20"
        :items="items"
        height="70vh"
      >
        <template v-slot:[`item.custName`]="{ item }">
          <td class="max-width-7 text-truncate">
            <span
              class="text-name-hover cursor-pointer"
              @click="goToDetailCust(item.custId)"
            >
              {{ item.custName }}
            </span>
          </td>
        </template>
        <template v-slot:[`item.waitOrder`]="{ item }">
          <td class="max-width-7 text-truncate">
            {{ formatDecimal(item.amount-(item.successDelivery + item.pickFail)) }}
          </td>
        </template>
        <template v-slot:[`item.successDelivery`]="{ item }">
          <td class="max-width-7 text-truncate">
            {{ formatDecimal(item.successDelivery) }}
          </td>
        </template>
        <template v-slot:[`item.pickFail`]="{ item }">
          <td class="max-width-7 text-truncate">
            {{ formatDecimal(item.pickFail) }}
          </td>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <td class="max-width-7 text-truncate">
            {{ formatDecimal(item.amount) }}
          </td>
        </template>
        <template v-slot:[`item.saleName`]="{ item }">
          <td class="max-width-7 text-truncate">
            <span
              class="text-name-hover cursor-pointer"
              @click="goToDetailEmployee(item.saleId)"
            >
              {{ item.saleName }}
            </span>
          </td>
        </template>
        <template v-slot:footer>
          <custom-pagination
            :page="page"
            :start-record="computedStartRecord"
            :end-record="computedEndRecord"
            :total-record="totalRecord"
            :total-page="totalPage"
            :on-change-page="onChangePage"
          />
        </template>
      </v-data-table>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import DialogConfirmTokenExpried from '@/components/dialog/DialogConfirmTokenExpried.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import constants from '@/constants'
import moment from 'moment'
import routePaths from '@/router/routePaths'
import filterItems from "@/helpers/filterItems";
import { mapActions } from 'vuex'
import { StringUtils } from '@/helpers/stringUtils'
import { DateTimeUtils } from '@/helpers/dateTimeUtils'
import { DataUtils } from '@/helpers/dataUtils'
import { StorageService } from '@/services/storageService'
import { OrderService } from '@/services/orderService'

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    CustomPagination,
    Breadscrumbs
  },
  mixins: [filterItems],
  data() {
    return {
      objSearch: '',
      constants,
      menu: false,
      dates: [],
      datesFm: [],
      // Date from.
      menuDateFrom: null,
      dateFromPicker: null,
      dateFromValue: null,
      errorDateFrom: null,
      focusDateFrom: false,

      // Date to.
      menuDateTo: null,
      dateToPicker: null,
      dateToValue: null,
      errorDateTo: null,
      focusDateTo: false,

      errorDate: null,
      headers: [
        {
          text: this.$t('customer_name'),
          value: 'custName',
          width: '20%',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('amount_process_order'),
          sortable: false,
          value: 'waitOrder',
          width: '15%',
          class: 'text-subtitle-2'
        },

        {
          text: this.$t('amount_success_order'),
          value: 'successDelivery',
          width: '15%',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('amount_fail_order'),
          value: 'pickFail',
          width: '15%',
          sortable: true,
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('total_sended_order'),
          value: 'amount',
          width: '15%',
          sortable: true,
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('sale_name'),
          sortable: false,
          value: 'saleName',
          width: '15%',
          class: 'text-subtitle-2'
        }
      ],
      items: [],
      totalPage: 0,
      page: 1,
      totalRecord: 0,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: '',
      showLoading: false,
      showConfirmTokenExpried: false,
      customerSelect: null,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      lstCustomer: [
        {
          name: this.$t('personal'),
          code: 1
        },
        {
          name: this.$t('shop'),
          code: 2
        },
        {
          name: this.$t('choice-customer'),
          code: null
        },
      ],
      breadcrumbs: [
        {
          text: 'statis_order',
          disabled: true,
          isActive: true,
        },
      ],
      lstPostOffice: [],
      selectPostOffice: null,
      waitOrder: 0,
      totalOrder: 0
    }
  },
  computed: {
    localDate() {
      return this.$i18n.locale === "vi" ? "vi-VN" : "en-US";
    },
    dateRangeText() {
      return this.datesFm.join(' - ')
    },
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.itemsPerPage + 1
      } else {
        return 0
      }
    },
    computedEndRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.itemsPerPage + this.items.length
      } else {
        return (this.page - 1) * constants.itemsPerPage
      }
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    },
    checkRoleBtn() {
      return this.currentRole.includes("ROLE_ADMIN")
    },
  },
  watch: {
    dates(val) {
      const newDates = []
      val.forEach((i) => {
        const item = this.formatDate(i)
        newDates.push(item)
      })
      this.datesFm = newDates
    }
  },
  created() {
    if (window.location.pathname && (!this.currentRole.includes("ROLE_ADMIN") && !this.currentRole.includes("ROLE_SALE") && !this.currentRole.includes("ROLE_ROOT"))) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData()
  },
  methods: {
    ...mapActions('layout', ['setBreadcrumbs']),
    async initData() {
      this.search()
    },
    async search() {
      this.showLoading = true
      const pars = this.bindRequestPars()
      const { status, data } = await OrderService.statisAmount(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord
        this.totalPage = DataUtils.calculatePage(data.totalRecord)
        if (this.totalRecord > 0) {
          this.items = data.data
          this.totalOrder = this.formatDecimal(data.totalOrder)
        } else {
          this.items = []
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
      }
    },
    bindRequestPars() {
      const pars = {
        currentPage: this.page,
        limit: constants.itemsPerPage,
        fromDate: this.dateFromPicker,
        toDate: this.dateToPicker
      }

      if (this.dates && this.dates.length > 0) {
        pars['fromDate'] = this.dates[0].trim()

        if (this.dates.length > 1) {
          pars['toDate'] = this.dates[1].trim()
        }
      }

      return pars
    },
    formatDecimal(val) {
      return val !== undefined ? StringUtils.formatDecimal(val) : ''
    },
    getTextCustomer(item) {
      if (item === 1) {
        return this.$t('personal')
      }
      return this.$t('shop')
    },
    viewDetail(item) {
      this.$router.push({
        path: routePaths.CUSTOMER_DETAIL,
        query: { id: item.id }
      })
      // this.addBreadCrumbs(routePaths.CUSTOMER_DETAIL)
    },
    onClearDateFrom() {
      this.dateFromPicker = null;
      this.dateFromValue = null;
      this.errorDate = null;
      this.errorDateFrom = null;
      this.onChangeDateTo();
      if (this.errorDate) return;
    },
    onClearDateTo() {
      this.dateToPicker = null;
      this.dateToValue = null;
      this.errorDate = null;
      this.errorDateTo = null;
      this.onChangeDateFrom();
      if (this.errorDate) return;
    },
    onChangeDateFrom() {
      if (this.dateFromValue) {
        this.errorDate = null;
        this.errorDateFrom = null;
        var valid = moment(this.dateFromValue, "DD/MM/YYYY", true).isValid();

        if (!valid) {
          this.dateFromPicker = null;
          this.errorDateFrom = this.$t("dateFromInvalid");
        }

        if (!valid && !this.dateToValue) {
          this.errorDate = this.$t("dateFromInvalid");
          return;
        }

        if (!valid && this.errorDateTo === "dateToInvalid") {
          this.errorDate = this.$t("dateFromAndToInvalid");
          return;
        }

        if (!valid) {
          this.errorDate = this.$t("dateFromInvalid");
          return;
        }

        if (this.errorDateTo) {
          this.errorDate = this.errorDateTo;
          return;
        }

        // Case valid.
        // If dateFrom valid but dateTo inValied.

        if (this.dateToValue) {
          this.dateFromPicker = null;
          const dateTo = moment(this.dateToValue, "DD/MM/YYYY");
          const dateFrom = moment(this.dateFromValue, "DD/MM/YYYY");
          const diff = dateTo.diff(dateFrom, "days");
          this.dateFromPicker = moment(this.dateFromValue, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );

          if (diff < 0) {
            this.errorDate = this.$t("messError.compareDate");
            return;
          }

          if (diff > 365) {
            this.errorDate = this.$t("messError.dateExpire");
            return;
          }
        }
        return;
      }

      // case dateFromValue = null.
      if (this.isRequired && !this.dateToValue) {
        this.errorDate = this.$t("messError.dateRequired");
        return;
      }

      if (this.isRequired) {
        this.errorDate = this.$t("dateFromRequired");
        this.errorDateFrom = this.$t("dateFromRequired");
      }
    },
    onChangeDateTo() {
      if (this.dateToValue) {
        this.errorDate = null;
        this.errorDateTo = null;
        var valid = moment(this.dateToValue, "DD/MM/YYYY", true).isValid();

        if (!valid) {
          this.dateToPicker = null;
          this.errorDateTo = "dateToInvalid";
        }

        if (!valid && !this.dateFromValue) {
          this.errorDate = "dateToInvalid";
          return;
        }

        if (!valid && this.errorDateFrom === "dateFromInvalid") {
          this.errorDate = "dateFromAndToInvalid";
          return;
        }

        if (!valid) {
          this.errorDate = "dateToInvalid";
          return;
        }

        if (this.errorDateFrom) {
          this.errorDate = this.errorDateFrom;
          return;
        }

        // Case valid.
        // If dateFrom valid but dateTo inValied.

        if (this.isRequired && !this.dateFromValue) {
          this.errorDate = "dateFromRequired";
          this.errorDateFrom = "dateFromRequired";
          return;
        }

        if (this.dateFromValue) {
          this.dateToPicker = null;
          const dateTo = moment(this.dateToValue, "DD/MM/YYYY");
          const dateFrom = moment(this.dateFromValue, "DD/MM/YYYY");
          const diff = dateTo.diff(dateFrom, "days");
          this.dateToPicker = moment(this.dateToValue, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );

          if (diff < 0) {
            this.errorDate = this.$t("messError.compareDate");
            return;
          }

          if (diff > 365) {
            this.errorDate = this.$t("messError.dateExpire");
            return;
          }
        }

        return;
      }

      // case dateToValue = null.
      if (this.isRequired && !this.dateFromValue) {
        this.errorDate = "messError.dateRequired";
        return;
      }

      if (this.isRequired) {
        this.errorDate = "dateToRequired";
        this.errorDateTo = "dateToRequired";
      }
    },
    goToDetailCust(custId) {
      this.$router.push({
        path: routePaths.CUSTOMER_DETAIL,
        query: {
          id: custId,
        },
      });
    },
    goToDetailEmployee(saleId) {
      this.$router.push({
        path: routePaths.EMPLOYEE_DETAIL,
        query: {
          id: saleId,
        },
      });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    pickerDateFromChange(date) {
      this.dateFromValue = this.formatDate(date);
      this.onChangeDateFrom();
    },
    pickerDateToChange(date) {
      this.dateToValue = this.formatDate(date);
      this.onChangeDateTo();
    },
    onCreateCustomer() {
      this.$router.push(routePaths.CUSTOMERS_CREATE);
    },
    onClickSearch() {
      this.page = 1
      this.search()
    },
    onChangePage(page) {
      this.page = page
      this.search()
    },
    onSelectedDates() {
      this.$refs.menu.save(this.dates)
    },
    // formatDate(date) {
    //   return DateTimeUtils.formatDateTime(date, constants.formatDate)
    // },
    formatDateYmd(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate)
    },
    parseDate(date) {
      return DateTimeUtils.parseDate(date)
    },
    onInputDateRange(val) {
      if (!val) {
        this.dates = []
      }
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: '' }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/createMultiOrder.scss" lang="scss">
</style>
