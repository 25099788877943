 @@ -0,0 +1,168 @@
<template>
  <v-layout
    v-if="totalPage > 1"
    justify-space-between
    align-center
    class="px-4 mb-2"
  >
    <label class="label-center">
      {{ $t('display') }} {{ startRecord }} - {{ endRecord }} /
      {{ totalRecord }}
    </label>
    <div class="text-center">
      <nav role="navigation" aria-label="Pagination Navigation">
        <ul class="v-pagination v-pagination--circle theme--light">
          <!-- first -->
          <li>
            <button
              :disabled="firstDisabled"
              type="button"
              aria-label="First page"
              :class="
                firstDisabled
                  ? `v-pagination__navigation  v-pagination__navigation--disabled`
                  : `v-pagination__navigation`
              "
              @click="onFirstPage"
            >
              <i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-chevron-double-left theme--light"
              />
            </button>
          </li>
          <!-- previous -->
          <li>
            <button
              :disabled="previousDisabled"
              type="button"
              aria-label="Previous page"
              :class="
                previousDisabled
                  ? `v-pagination__navigation  v-pagination__navigation--disabled`
                  : `v-pagination__navigation`
              "
              @click="onPreviousPage"
            >
              <i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-chevron-left theme--light"
              />
            </button>
          </li>

          <li>
            <span
              class="mx-2 px-3 v-pagination__item--active primary rounded-lg text-center"
            >
              {{ page }}
            </span>
            / <span class="mx-2">{{ totalPage }}</span>
          </li>
          <!-- next -->
          <li>
            <button
              :disabled="nextDisabled"
              type="button"
              aria-label="Next page"
              :class="
                nextDisabled
                  ? `v-pagination__navigation  v-pagination__navigation--disabled`
                  : `v-pagination__navigation`
              "
              @click="onNextPage"
            >
              <i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-chevron-right theme--light"
              />
            </button>
          </li>
          <!-- last -->
          <li>
            <button
              :disabled="lastDisabled"
              type="button"
              aria-label="Last page"
              :class="
                lastDisabled
                  ? `v-pagination__navigation  v-pagination__navigation--disabled`
                  : `v-pagination__navigation`
              "
              @click="onLastPage"
            >
              <i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-chevron-double-right theme--light"
              />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </v-layout>
</template>
<script>
export default {
  name: 'CustomPagination',
  props: {
    page: {
      type: Number,
      default: 1
    },
    startRecord: {
      type: Number,
      default: null
    },
    endRecord: {
      type: Number,
      default: null
    },
    totalRecord: {
      type: Number,
      default: null
    },
    totalPage: {
      type: Number,
      default: null
    },
    onChangePage: {
      type: Function,
      default: null
    }
  },
  computed: {
    firstDisabled() {
      return this.page - 1 <= 1
    },
    previousDisabled() {
      return this.page === 1
    },
    nextDisabled() {
      return this.page === this.totalPage
    },
    lastDisabled() {
      return this.page + 1 >= this.totalPage
    }
  },
  methods: {
    onFirstPage() {
      this.onChangePage(1)
    },
    onPreviousPage() {
      const val = this.page - 1
      this.onChangePage(val)
    },
    onNextPage() {
      const val = this.page + 1
      this.onChangePage(val)
    },
    onLastPage() {
      const val = this.totalPage
      this.onChangePage(val)
    }
  }
}
</script>
