import constants from '@/constants'
import { format } from 'date-fns'
import moment from 'moment'

export class DateTimeUtils {
  static formatDateTime(val, typeFm) {
    if (!val) return ''

    const dateTime = new Date(val)
    return format(dateTime, typeFm)
  }

  static parseDate(date) {
    if (!date) return null

    const [day, month, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  static getTomorrow() {
    return moment().add(1, 'days').format(constants.formatDateYmd)
  }

  static getNextDateByAddDay(date, addDay) {
    return moment(date).add(addDay, 'days').format(constants.formatDateYmd)
  }

  static startOfDay(date) {
    return moment(date).format(constants.formatDateTimeUtc)
  }

  static endOfDay(date) {
    return moment(date).add(1, 'days').add(-1, 'milliseconds').format(constants.formatDateTimeUtc)
  }

  static formatPickUpDateTime(from, to) {
    if (!from) return ''

    const fromDt = new Date(from)
    const toDt = new Date(to)
    return `${format(fromDt, constants.formatDateTimeHm)} - ${format(
      toDt,
      constants.formatTimeHm
    )}`
  }
}
