var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.totalPage > 1)?_c('v-layout',{staticClass:"px-4 mb-2",attrs:{"justify-space-between":"","align-center":""}},[_c('label',{staticClass:"label-center"},[_vm._v(" "+_vm._s(_vm.$t('display'))+" "+_vm._s(_vm.startRecord)+" - "+_vm._s(_vm.endRecord)+" / "+_vm._s(_vm.totalRecord)+" ")]),_c('div',{staticClass:"text-center"},[_c('nav',{attrs:{"role":"navigation","aria-label":"Pagination Navigation"}},[_c('ul',{staticClass:"v-pagination v-pagination--circle theme--light"},[_c('li',[_c('button',{class:_vm.firstDisabled
                ? "v-pagination__navigation  v-pagination__navigation--disabled"
                : "v-pagination__navigation",attrs:{"disabled":_vm.firstDisabled,"type":"button","aria-label":"First page"},on:{"click":_vm.onFirstPage}},[_c('i',{staticClass:"v-icon notranslate mdi mdi-chevron-double-left theme--light",attrs:{"aria-hidden":"true"}})])]),_c('li',[_c('button',{class:_vm.previousDisabled
                ? "v-pagination__navigation  v-pagination__navigation--disabled"
                : "v-pagination__navigation",attrs:{"disabled":_vm.previousDisabled,"type":"button","aria-label":"Previous page"},on:{"click":_vm.onPreviousPage}},[_c('i',{staticClass:"v-icon notranslate mdi mdi-chevron-left theme--light",attrs:{"aria-hidden":"true"}})])]),_c('li',[_c('span',{staticClass:"mx-2 px-3 v-pagination__item--active primary rounded-lg text-center"},[_vm._v(" "+_vm._s(_vm.page)+" ")]),_vm._v(" / "),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.totalPage))])]),_c('li',[_c('button',{class:_vm.nextDisabled
                ? "v-pagination__navigation  v-pagination__navigation--disabled"
                : "v-pagination__navigation",attrs:{"disabled":_vm.nextDisabled,"type":"button","aria-label":"Next page"},on:{"click":_vm.onNextPage}},[_c('i',{staticClass:"v-icon notranslate mdi mdi-chevron-right theme--light",attrs:{"aria-hidden":"true"}})])]),_c('li',[_c('button',{class:_vm.lastDisabled
                ? "v-pagination__navigation  v-pagination__navigation--disabled"
                : "v-pagination__navigation",attrs:{"disabled":_vm.lastDisabled,"type":"button","aria-label":"Last page"},on:{"click":_vm.onLastPage}},[_c('i',{staticClass:"v-icon notranslate mdi mdi-chevron-double-right theme--light",attrs:{"aria-hidden":"true"}})])])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }